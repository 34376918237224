import { getS3SignUrl } from "@/Services/apis/common.api";
import {
  addNewSkillsApi,
  skillsListApi,
  switchRoleApi,
  updateAccountSettings,
  updateCompanyProfileDetailsApi,
  updateProfileDetailsApi,
  updateProfileDetailsInterviwerApi,
} from "@/Services/apis/profile.api";
import axiosMethods from "@/Services/https/https";
import { getLocalstorageData } from "@/Utils/CommonFuncs";
import { GetUserApiResponseType, GetUserFunParaTypes } from "@/Types/Profile/editProfileTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { updateInterViewerProfileRequestedSkills } from "./Interviewer/interviewerRole.slice";
import { setAbilities } from "./canAbility/canAbility.slice";
import { getAbilities } from "@/Utils/CANAbility/canAbilities";
import Cookie from "universal-cookie";
import i18n from "@/Utils/i18n";
// import type { PayloadAction } from "@reduxjs/toolkit";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  user: null,
  profilePic: {},
  coverPic: {},
  resume: {},
  coverLetter: {},
  fileData: {},
  skillsList: [],
  selectedDashboardData: {},
};
/* eslint-enable @typescript-eslint/no-explicit-any */

//Get user details after login
/* eslint-disable @typescript-eslint/no-explicit-any */

export const getUser = createAsyncThunk(
  "getUser",
  async (
    { router, resource, profileRole, token, dispatch, fetchDashboard, roleprofileid }: GetUserFunParaTypes
    // { rejectWithValue },
  ) => {
    const url = `/user-profile`;
    try {
      const response: GetUserApiResponseType = await axiosMethods.post(
        url,
        {},
        {
          headers: {
            Authorization: `${token}`,
            Resource: resource && resource,
            ProfileRole: profileRole && profileRole,
            RoleProfileId: roleprofileid,
          },
        }
      );
      if (fetchDashboard) {
        const bodyData = {
          roleProfileId: response?.userData?.roleProfiles?.find((roleProfile) => roleProfile?.profileType === "Default")
            ?._id,
        };
        const value = {
          role: "Default",
          resource: "select-dashboard",
          bodyData,
          dispatch,
          updatedRole: "Default",
          router,
          profileCompletion: response?.userData?.accountSettings?.profileCompletion === 0,
        };
        dispatch(switchRole(value));
      }
      if (router) {
        response?.userData?.accountSettings?.profileCompletion === 0
          ? router?.replace("/profile/edit")
          : router?.replace("/feeds");
      }
      return response;
    } catch (error) {
      // return rejectWithValue(error.response.data);
    }
  }
);
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const fileUpload = createAsyncThunk("editProfileFileUpload", async (values: any, { rejectWithValue }) => {
  const file = values.blobFile;
  values.role = getLocalstorageData("currentDashboardRole");
  try {
    const data: any = await getS3SignUrl(values);

    if (data?.code !== 200) return rejectWithValue(data);
    if (data?.code === 200) {
      if (values?.type === "profilePic") {
        values?.dispatch(
          setProfilePic({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        // values.formik.setFieldValue(values?.type, 'Done')
      }
      if (values?.type === "coverPic") {
        values?.dispatch(
          setCoverPic({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        // values.formik.setFieldValue(values?.type, 'Done')
      }
      if (values?.type === "resume") {
        values?.dispatch(
          setResume({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        values.formik.setFieldValue(values?.type, "Done");
      }
      if (values?.type === "coverLetter") {
        values?.dispatch(
          setCoverLetter({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        values.formik.setFieldValue(values?.type, "Done");
      }
    }
    return { data };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const updateProfile = createAsyncThunk("updateProfile", async (values: any, { rejectWithValue }) => {
  try {
    const updateprofileDetails: any = await updateProfileDetailsApi(values);
    if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
    if (updateprofileDetails?.code === 200) {
      toast.success(updateprofileDetails?.message);
      if (values?.type === "basic-info") {
        values.dispatch(getUser({ resource: "get-user", profileRole: values.role }));
      }
      values?.handleCloseDeleteModel(false);
    } else {
      values?.handleCloseDeleteModel(false);
    }
    return updateprofileDetails;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const updateInterViewerProfile = createAsyncThunk(
  "updateInterViewerProfile",
  async ({ values, setOpenSkillModelInterviewer }: any, { rejectWithValue }) => {
    try {
      const updateprofileDetails: any = await updateProfileDetailsInterviwerApi(values);
      if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
      if (updateprofileDetails?.code === 200) {
        toast.success(updateprofileDetails?.message);
        values.dispatch(getUser({ resource: "get-user", profileRole: values.role }));
        if (values?.fromConfig) {
          values?.router?.replace("/profile/view");
        }
        setOpenSkillModelInterviewer(false);
      }
      return updateprofileDetails;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCompanyProfile = createAsyncThunk("updateProfile", async (values: any, { rejectWithValue }) => {
  try {
    const updateprofileDetails: any = await updateCompanyProfileDetailsApi(values);
    if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
    if (updateprofileDetails?.code === 200) {
      toast.success(updateprofileDetails?.message);
    }
    return updateprofileDetails;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const addNewSkills = createAsyncThunk(
  "addNewSkills",
  async (
    { valueForAddSkillsApi, value, dispatch, setOpenSkillModelInterviewer, requestedSkill }: any,
    { rejectWithValue }
  ) => {
    try {
      const addNewSkillsData: any = await addNewSkillsApi(valueForAddSkillsApi);
      if (addNewSkillsData?.code !== 200) return rejectWithValue(addNewSkillsData);
      if (addNewSkillsData?.code === 200) {
        toast.success(addNewSkillsData?.message);
        if (getLocalstorageData("currentDashboardRole") === "Interviewer") {
          if (requestedSkill) {
            dispatch(
              updateInterViewerProfileRequestedSkills({
                values: value,
                setOpenSkillModelInterviewer,
              })
            );
          } else {
            dispatch(
              updateInterViewerProfile({
                values: value,
                setOpenSkillModelInterviewer,
              })
            );
          }
        } else {
          dispatch(updateProfile(value));
        }
      }
      return addNewSkillsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getListOfSkills = createAsyncThunk("skillsListApi", async (values: any, { rejectWithValue }) => {
  try {
    const updateprofileDetails: any = await skillsListApi(values);
    if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
    return updateprofileDetails;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const updateAccountProfile = createAsyncThunk(
  "updateAccountProfile",
  async (values: any, { rejectWithValue }) => {
    try {
      const updateprofileDetails: any = await updateAccountSettings(values);
      if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
      if (updateprofileDetails?.code === 200) {
        if (values?.key === "isResumePublic") {
          return updateprofileDetails;
        }
        values.dispatch(getUser({ resource: "get-user", profileRole: values.role }));
      }
      return updateprofileDetails;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Switch Account From Navbar
export const switchRole = createAsyncThunk("switchRole", async (values: any, { rejectWithValue }) => {
  const cookie = new Cookie();
  try {
    const updateprofileDetails: any = await switchRoleApi(values);
    if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
    if (updateprofileDetails?.code === 200) {
      localStorage.setItem("currentDashboardRole", values?.updatedRole);
      cookie.set("currentDashboardRole", values?.updatedRole, {
        path: "/",
      });
      values.dispatch(setAbilities(getAbilities(values?.updatedRole)));
      !values?.profileCompletion &&
        values.dispatch(
          getUser({
            resource: "get-user",
            profileRole: values?.updatedRole,
            roleprofileid: updateprofileDetails?.roleProfileResources?._id,
          })
        );
      if (values?.updatedRole === "Default") {
        toast.success(i18n.t("cms_dashobard_changed_message_default"));
      }
      if (values?.updatedRole === "Interviewer") {
        toast.success(i18n.t("cms_dashobard_changed_message_interviewer"));
      }
      if (values?.updatedRole !== "Interviewer" && values?.updatedRole !== "Default") {
        toast.success(i18n.t("cms_dashobard_changed_message_company"));
      }
      if (values?.profileCompletion) {
        window.location.href = "/profile/edit";
      } else {
        window.location.href = "/feeds";
      }
    }
    return updateprofileDetails;
  } catch (error: any) {
    toast.error("Unable to switch role");
    return rejectWithValue(error.response.data);
  }
});

export const userProfileSlice = createSlice({
  name: "userProfileSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.user = action?.payload;
      localStorage.setItem("langName", action?.payload?.language);
    },
    setProfilePic: (state, action) => {
      state.profilePic = action.payload;
    },
    setCoverPic: (state, action) => {
      state.coverPic = action.payload;
    },
    setResume: (state, action) => {
      state.resume = action.payload;
    },
    setCoverLetter: (state, action) => {
      state.coverLetter = action.payload;
    },
    setHandleFile: (state, action) => {
      state.fileData = action.payload;
    },
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */

  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (_state) => {})
      .addCase(getUser.fulfilled, (state, action: any) => {
        state.user = { ...action?.payload?.userData };
        state.profilePic = action?.payload?.userData.profileImage;
        state.coverPic = action?.payload?.userData.coverImage;
        state.resume = action?.payload?.userData.resume ?? "";
        state.coverLetter = action?.payload?.userData.coverLetter ?? "";
      })
      .addCase(getUser.rejected, (_state) => {})
      .addCase(fileUpload.pending, (_state) => {})
      .addCase(fileUpload.fulfilled, (_state, _action) => {})
      .addCase(fileUpload.rejected, (_state) => {})
      .addCase(getListOfSkills.fulfilled, (_state, _action) => {
        _state.skillsList = _action.payload.skillsList;
      })
      .addCase(switchRole.fulfilled, (_state, _action) => {
        _state.selectedDashboardData = _action.payload.roleProfileResources;
      });
  },
  /* eslint-enable @typescript-eslint/no-explicit-any */
});

export const { updateUserData, setProfilePic, setCoverPic, setResume, setCoverLetter, setHandleFile } =
  userProfileSlice.actions;

export default userProfileSlice.reducer;
